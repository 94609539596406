import React from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as webSocketActions from '../redux/actions/webSocketActions';

import SEO from '../components/SEO';

function TexasDisclosure({ siteConfig }) {
  return (
    <>
      <SEO title={'Texas Broker Disclosure Statement'} />

      <div className="article-container">
        <h1>
          <div>{siteConfig.brand} LLC Texas Broker Disclosure Statement</div>
        </h1>

        <div className="page-container text-container">
          <div className="page">
            <div className="inner">
              <div className="article-text full">
                <p>
                  Pursuant to the Substantive Rules Applicable to Electric
                  Service Providers, at Texas Administrative Code, Title 16,
                  Part 2, Chapter 25, Subchapter R, &sect; 25.486(f),{' '}
                  {siteConfig.brand} LLC hereby discloses the following
                  information:
                </p>
                <p>
                  The broker&rsquo;s registered name, business mailing address,
                  and contact information:
                </p>
                <ul>
                  <li>
                    {siteConfig.brand} LLC is an Arizona Limited Liability
                    Company that owns and operates the{' '}
                    <a
                      href={'https://www.' + siteConfig.domain}
                      className="link">
                      www.{siteConfig.domain}
                    </a>{' '}
                    website and has a mailing address of 2525 E Camelback Road
                    STE 810 in Phoenix, AZ 85016. We can also be contacted by
                    emailing{' '}
                    <a
                      href={'mailto:support@' + siteConfig.domain}
                      className="link">
                      support@{siteConfig.domain}
                    </a>{' '}
                    or by calling (888) 969-4367
                  </li>
                </ul>
                <p>The broker&rsquo;s commission registration number:</p>
                <ul>
                  <li>
                    {siteConfig.brand} LLC is registered with the Public Utility
                    Commission of Texas as an Electric Broker under registration
                    number {siteConfig.txLicense}
                  </li>
                </ul>
                <p>
                  The registered name of any REP that is an affiliate of the
                  broker:
                </p>
                <ul>
                  <li>
                    {siteConfig.brand} LLC is not affiliated with any Retail
                    Electric Provider (REP) as defined or contemplated under the
                    Substantive Rules Applicable to Electric Utility Service
                    Providers, at Texas Administrative Code, Title 16, Part 2,
                    Chapter 25, Subchapter A, &sect; 25.5.
                  </li>
                </ul>
                <p>
                  A clear description of the services the broker will provide
                  for the client:
                </p>
                <ul>
                  <li>
                    {siteConfig.brand} LLC provides an online electricity choice
                    shopping dashboard that lets users compare the pricing and
                    terms of various electric plans from various providers, and
                    assists in the purchase of one of those plans should user
                    choose to utilize the site for that purpose.
                  </li>
                </ul>
                <p>
                  The duration of the agreement to provide brokerage services,
                  if applicable:
                </p>
                <ul>
                  <li>
                    If you use the{' '}
                    <a
                      href={'https://www.' + siteConfig.domain}
                      className="link">
                      www.{siteConfig.domain}
                    </a>{' '}
                    website to purchase an electric plan only, there is no
                    agreement between you and {siteConfig.brand} LLC beyond that
                    activity. If you use the{' '}
                    <a
                      href={'https://www.' + siteConfig.domain}
                      className="link">
                      www.{siteConfig.domain}
                    </a>{' '}
                    website to pull and or monitor your electric usage through
                    Smart Meter Texas the agreement between you,{' '}
                    {siteConfig.brand} LLC and Smart Meter Texas will continue
                    as outlined in the Smart Meter Texas agreement you
                    &lsquo;confirmed&rsquo; thought our site or email (usually
                    12 months).
                  </li>
                </ul>
                <p>
                  A description of how the broker will be compensated for
                  providing brokerage services and by whom:
                </p>
                <ul>
                  <li>
                    <a
                      href={'https://www.' + siteConfig.domain}
                      className="link">
                      www.{siteConfig.domain}
                    </a>{' '}
                    is free to use. If a user uses our site to purchase an
                    electric plan we are typically (but not always) compensated
                    by the provider of that plan in the form of a channel
                    partner sales commission.
                  </li>
                </ul>
                <p>
                  How the client can terminate the agreement to provide
                  brokerage services, if applicable:
                </p>
                <ul>
                  <li>
                    A user can terminate their Broker or Smart Meter Texas
                    agreement with {siteConfig.brand} LLC at any time for any
                    reason by sending a notice of termination to{' '}
                    <a
                      href={'mailto:support@' + siteConfig.domain}
                      className="link">
                      support@{siteConfig.domain}
                    </a>
                  </li>
                </ul>
                <p>
                  The amount of any fee or other cost the client will incur for
                  terminating the agreement to provide brokerage services, if
                  applicable:
                </p>
                <ul>
                  <li>
                    There is no fee or other cost associated with a user
                    terminating their Broker or Smart Meter Texas agreement with{' '}
                    {siteConfig.brand} LLC
                  </li>
                </ul>
                <p>
                  The commission&rsquo;s telephone number and email address for
                  complaints and inquiries:
                </p>
                <ul>
                  <li>
                    A person may file an informal complaint with the commission
                    by contacting the commission at: Public Utility Commission
                    of Texas, Customer Protection Division, P.O. Box 13326,
                    Austin, Texas 78711-3326; (512) 936-7120 or in Texas
                    (toll-free) 1-888-782-8477, fax (512) 936-7003, e-mail
                    address:{' '}
                    <a href="mailto:customer@puc.texas.gov" className="link">
                      customer@puc.texas.gov
                    </a>
                    , Internet website address:&nbsp;
                    <a
                      href="https://www.puc.texas.gov"
                      className="link"
                      target="mi_offsite">
                      puc.texas.gov
                    </a>
                    , TTY (512) 936-7136, and Relay Texas (toll-free)
                    1-800-735-2989.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function mapStateToProps(state) {
  return {
    siteConfig: state.commonData.siteConfig
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      pageLoading: bindActionCreators(webSocketActions.pageLoading, dispatch)
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TexasDisclosure);
